import { useState, useRef, useContext, useEffect } from 'react'

import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react'
import { Button, Input } from "@chakra-ui/react"



import { FunnelsContext } from '../../store/funnels'
import { UserContext } from '../../store/user'

import { useRouter } from 'next/router'

import axios from 'axios'

const RenameModal = ({isOpen, onClose}) => {
	const cancelRef = useRef()
	const { state: funnelsState, dispatch: funnelsDispatch } = useContext(FunnelsContext)
	const { state: userState } = useContext(UserContext)
	const [title, setTitle] = useState('')
	const router = useRouter()

	useEffect(() => {
		if (funnelsState.funnels) {
			const funnel = funnelsState.funnels.find(f => f.id == router.query.funnelId)
			setTitle(funnel?.title ?? '')
		} else {
			setTitle('')
		}
	}, [isOpen])

	const renameFunnel = async () => {
		funnelsDispatch({ type: 'SET_FUNNELS', value: funnelsState.funnels.map(f => (f.id === router.query.funnelId) ? {...f, title} : f) })
		await axios.put(`/api/funnels/${router.query.funnelId}`, {title}, { headers: { 'Content-Type': 'application/json', authorization: userState.token } })
	}
	return (
		<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						Rename Funnel
									</AlertDialogHeader>
					<AlertDialogBody>
						<Input placeholder="Enter a new name" value={title} onChange={(e) => setTitle(e.target.value)}/>
								</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							Cancel
										</Button>
						<Button colorScheme="purple" onClick={() => {renameFunnel(); onClose()}} ml={3}>
							Rename
										</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
}
export default RenameModal