const showBackground = true
export {showBackground}

const background = require('../../public/assets/Misc/Background.svg')
export {background}

const backgroundUrl = showBackground ? require('../../public/assets/Screens/URL.svg') : require('../../public/assets/Screens_Bg/URL.svg')
export {backgroundUrl}

const steps = [
	{ name: 'Generic', imageUrl: showBackground ? require('../../public/assets/Screens/Generic.svg') : require('../../public/assets/Screens_Bg/Generic.svg') },
	{ name: 'Download', imageUrl: showBackground ? require('../../public/assets/Screens/Download.svg') : require('../../public/assets/Screens_Bg/Download.svg') },
	{ name: 'Sign Up', imageUrl: showBackground ? require('../../public/assets/Screens/SignUp.svg') : require('../../public/assets/Screens_Bg/SignUp.svg') },
	{ name: 'Checkout', imageUrl: showBackground ? require('../../public/assets/Screens/Checkout.svg') : require('../../public/assets/Screens_Bg/Checkout.svg') },
	{ name: 'Calendar', imageUrl: showBackground ? require('../../public/assets/Screens/Calendar.svg') : require('../../public/assets/Screens_Bg/Calendar.svg') },
	{ name: 'Survey', imageUrl: showBackground ? require('../../public/assets/Screens/Survey.svg') : require('../../public/assets/Screens_Bg/Survey.svg') },
	{ name: 'CTA', imageUrl: showBackground ? require('../../public/assets/Screens/CallToAction.svg') : require('../../public/assets/Screens_Bg/CallToAction.svg') },
	{ name: 'CTA 2', imageUrl: showBackground ? require('../../public/assets/Screens/CallToAction2.svg') : require('../../public/assets/Screens_Bg/CallToAction2.svg') },
	{ name: 'CTA 3', imageUrl: showBackground ? require('../../public/assets/Screens/CallToAction3.svg') : require('../../public/assets/Screens_Bg/CallToAction3.svg') },
	{ name: 'Comments', imageUrl: showBackground ? require('../../public/assets/Screens/Comments.svg') : require('../../public/assets/Screens_Bg/Comments.svg') },
	{ name: 'Post', imageUrl: showBackground ? require('../../public/assets/Screens/Post.svg') : require('../../public/assets/Screens_Bg/Post.svg') },
	{ name: 'User', imageUrl: showBackground ? require('../../public/assets/Screens/User.svg') : require('../../public/assets/Screens_Bg/User.svg') },
	{ name: 'Thank you', imageUrl: showBackground ? require('../../public/assets/Screens/ThankYou.svg') : require('../../public/assets/Screens_Bg/ThankYou.svg') },
	{ name: 'Popup', imageUrl: showBackground ? require('../../public/assets/Screens/Popup.svg') : require('../../public/assets/Screens_Bg/Popup.svg') }
]
export {steps}
