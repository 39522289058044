const makeElementScrollable = (slider) => {
    let isDown = false
    let startX
    let scrollLeft

    const handleDown = e => {
        // e.preventDefault()
        isDown = true
        slider.classList.add('active')
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
    }
    const handleLeaveOut = e => {
        e.preventDefault()
        isDown = false
        slider.classList.remove('active')
    }
    const handleMove = e => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - slider.offsetLeft
        const walk = x - startX
        slider.scrollLeft = scrollLeft - walk
    }
    slider.addEventListener('mousedown', handleDown)
    slider.addEventListener('mouseleave', handleLeaveOut)
    slider.addEventListener('mouseup', handleLeaveOut)
    slider.addEventListener('mousemove', handleMove)
    // slider.addEventListener('touchstart', handleDown)
    // slider.addEventListener('touchend', handleLeaveOut)
    // slider.addEventListener('_', handleLeaveOut)
    // slider.addEventListener('touchmove', handleMove)

    return () => {
        slider.removeEventListener('mousedown', handleDown)
        slider.removeEventListener('mouseleave', handleLeaveOut)
        slider.removeEventListener('mouseup', handleLeaveOut)
        slider.removeEventListener('mousemove', handleMove)
    }
}
export {makeElementScrollable}

const isTouchDevice = () => {
    try {
        const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
        const mq = (query) => window.matchMedia(query).matches
        if (('ontouchstart' in window) || (typeof window.DocumentTouch !== "undefined" && document instanceof window.DocumentTouch)) {
            return true
        }
        return mq(['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(''))
    } catch (e) {
        console.error('(Touch detect failed)', e)
        return false
    }
}
export {isTouchDevice}
