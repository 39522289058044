import { useContext, useState } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import { InputGroup, InputLeftAddon, Input, Text, Textarea, Button, Box } from '@chakra-ui/react'

import { UserContext } from '../../store/user'

import axios from 'axios'

const IssueModal = ({ isOpen, onClose }) => {
	const {state: userState} = useContext(UserContext)
	const [text, setText] = useState('')
	const [email, setEmail] = useState(null)
	const submitFeedback = async () => {
		await axios.post(`/api/feedback/issue`, {email, text}, { headers: { 'Content-Type': 'application/json', authorization: userState.token } })
		setText('')
	}
	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent marginTop={'0px'}  marginBottom="60px" maxW="520px">
				<ModalHeader>Submit an issue</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box>
					<Text marginBottom="2">We try to resolve issues ASAP. To investigate best, it's helpful to know as much as possible about the problem.</Text>
						<Textarea marginBottom="2" placeholder="The issue you facing..." onChange={(e) => setText(e.target.value)}/>
						{!userState.token && <>
							<Text marginBottom="2">Enter an email to reach you for more info if it is needed.</Text>
							<InputGroup marginBottom="2">
								<InputLeftAddon children="Email" />
								<Input type="email" placeholder="seth@godin.com (Optional)" onChange={(e) => setEmail(e.target.value)}/>
							</InputGroup>
						</>}
					</Box>
				</ModalBody>
				<ModalFooter>
					<Button variant="ghost" onClick={onClose}>Close</Button>
					<Button colorScheme="purple" ml={3} isDisabled={text.length === 0} onClick={() => {onClose(); submitFeedback()}}>Submit</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default IssueModal