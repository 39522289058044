import { Box, Text, Flex } from '@chakra-ui/react'

import {steps, backgroundUrl} from '../shared/Steps'

import BottomBarElement from './BottomBarElement'

const BottomBarContainer = ({containerRef}) => {
    return (
        <Box position="relative">
            <Box position="absolute" margin="-44px 0 0 40px" bgColor="purple.500" borderTopLeftRadius="8px" borderTopRightRadius="8px">
                <Text fontWeight="semibold" fontSize="md" padding="10px 20px" color="white">Funnel Steps</Text>
            </Box>
            <Box borderWidth="1px" borderRadius="lg">
                <Flex ref={containerRef} padding="24px 40px 16px 40px" overflow="scroll" cursor="move" backgroundColor="white">
                    <BottomBarElement key={-1} imageUrl={backgroundUrl} name={'URL'} isPrimary={true}/>
                    {steps.map((e, i) => <BottomBarElement key={i} imageUrl={e.imageUrl} name={e.name} />)}
                </Flex>
            </Box>
        </Box>
    )
}
export default BottomBarContainer
