import { Heading, Box } from '@chakra-ui/react'

const example = require('./../../public/assets/Misc/Example.svg')

const CanvasBackground = () => {
    return (
        <Box userSelect="none">
            <img src={example} alt='example' draggable="false" style={{ filter: 'grayscale(100%)', margin: 'auto' }} />
            <Heading size="md" color="grey" fontWeight="600" textAlign="center">Drag a funnel step <br />to get started 👇</Heading>
        </Box>
    )
}
export default CanvasBackground