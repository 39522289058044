import { useContext } from 'react'

import { Box, Button, Icon, forwardRef, IconButton, useDisclosure } from '@chakra-ui/react'
import { Menu, MenuButton, MenuList, MenuItem, MenuDivider } from "@chakra-ui/react"

import { TrashIcon, PencilSquareIcon } from '@heroicons/react/20/solid'

import { UserIcon, Bars3Icon, LinkIcon, ArrowTopRightOnSquareIcon, PlusIcon, CheckCircleIcon, ArrowsRightLeftIcon, ChevronDownIcon } from '@heroicons/react/24/solid'

import DeleteFunnel from '../modals/DeleteFunnel'
import RenameFunnel from '../modals/RenameFunnel'


import { UserContext } from '../../store/user'
import { FunnelsContext } from '../../store/funnels'

import LogoutIcon from '../icons/LogoutIcon'
import SaveIcon from '../icons/SaveIcon'
import LoginIcon from '../icons/LoginIcon'

import { useGoogleLogin } from '@react-oauth/google'


const defaultName = 'Untitled'

const ButtonMaxWidth = forwardRef(({ children, ...props }, ref) => (
	<Button ref={ref} {...props}>
		<span style={{ textOverflow: 'ellipsis', maxWidth: '160px', overflow: 'hidden', whiteSpace: 'nowrap' }}>
			{children}
		</span>
	</Button>
))

export const SelectProject = () => {
	const { disclosures } = useContext(UserContext)
	const { state: funnelsState, newFunnel, selectFunnel } = useContext(FunnelsContext)

	const deleteDisclosure = useDisclosure()
	const renameModalDisclosure = useDisclosure()

	return (
		<Box position="absolute" top="20px" left="0" right="0" textAlign="center">
			<>
				<Menu isOpen={disclosures.selectMenuDisclosure.isOpen} onOpen={disclosures.selectMenuDisclosure.onOpen} onClose={disclosures.selectMenuDisclosure.onClose}>
					<MenuButton as={ButtonMaxWidth} rightIcon={<Icon as={ChevronDownIcon} />} marginRight="16px">
						{funnelsState.funnels[funnelsState.funnelIndex]?.title ?? defaultName}
					</MenuButton>
					<MenuList>
						{funnelsState.funnels.map((funnel, i) => <MenuItem key={i} onClick={() => selectFunnel(funnel)}>{funnel?.title ?? 'Untitled'}</MenuItem>)}
						{funnelsState.funnels.length > 1 && <MenuDivider />}
						<MenuItem icon={<Icon as={PlusIcon} />} onClick={() => newFunnel()}>New Funnel</MenuItem>
					</MenuList>
				</Menu>
				<IconButton icon={<Icon as={PencilSquareIcon} />} variant="ghost" onClick={renameModalDisclosure.onOpen} />
				<RenameFunnel isOpen={renameModalDisclosure.isOpen} onClose={renameModalDisclosure.onClose} />
				{funnelsState.funnels?.length > 1 && (
					<>
						<IconButton icon={<Icon as={TrashIcon} />} variant="ghost" onClick={deleteDisclosure.onOpen} />
						<DeleteFunnel isOpen={deleteDisclosure.isOpen} onClose={deleteDisclosure.onClose} />
					</>
				)}
			</>
		</Box>
	)
}


export const DesktopView = () => {
	const { state: userState, logout, showPortal, disclosures } = useContext(UserContext)
	const { state: funnelsState, toggleView, saveFunnel, onLoginSuccess } = useContext(FunnelsContext)

	const login = useGoogleLogin({ flow: 'auth-code', onSuccess: (res) => onLoginSuccess(res, false)})
	const status = userState.user?.subscriptionStatus
	return (
		!userState.token ?
			<>
				<Button marginRight="16px" isDisabled onClick={() => { }}>Share (soon)</Button>
				<Button marginRight="16px" onClick={toggleView}>{!funnelsState.showHits ? 'Hits' : '$'}</Button>
				<Button isDisabled={funnelsState.funnelNodes?.length === 0 && funnelsState.funnelEdges?.length === 0} onClick={disclosures.signupModalDisclosure.onOpen} marginRight="16px">Save</Button>
				<Button marginRight="16px" onClick={login}>Login</Button>
				<Button onClick={disclosures.signupModalDisclosure.onOpen} marginRight="16px">Sign up</Button>
				<Button onClick={disclosures.promiseModalDisclosure.onOpen} colorScheme="purple">Our Promise</Button>
			</> :
			<>
				<Button marginRight="16px" isDisabled onClick={() => { }}>Share (soon)</Button>
				<Button marginRight="16px" onClick={toggleView}>{!funnelsState.showHits ? 'Hits' : '$'}</Button>
				{(status !== 'active' && funnelsState.funnels?.length > 0 && <Button onClick={saveFunnel} marginRight="16px" colorScheme="purple">Save</Button>)}

				<Menu isOpen={disclosures.accountMenuDisclosure.isOpen} onOpen={disclosures.accountMenuDisclosure.onOpen} onClose={disclosures.accountMenuDisclosure.onClose}>
					<MenuButton as={Button} rightIcon={<Icon as={ChevronDownIcon} />}>
						Account
					</MenuButton>
					<MenuList>
						<MenuItem icon={<Icon as={ArrowTopRightOnSquareIcon} />} onClick={showPortal}>{status === 'active' ? 'Billing' : 'Subscribe'}</MenuItem>
						<MenuDivider />
						<MenuItem icon={<LogoutIcon />} onClick={logout}>Logout</MenuItem>
					</MenuList>
				</Menu>
			</>
	)
}

export const TabletView = () => {
	const { state: userState, logout, showPortal, disclosures } = useContext(UserContext)
	const { state: funnelsState, toggleView, saveFunnel, onLoginSuccess } = useContext(FunnelsContext)
	const status = userState.user?.subscriptionStatus

	const login = useGoogleLogin({ flow: 'auth-code', onSuccess: (res) => onLoginSuccess(res, false)})

	return (
		<Menu isOpen={disclosures.mobileMenuDisclosure.isOpen} onOpen={disclosures.mobileMenuDisclosure.onOpen} onClose={disclosures.mobileMenuDisclosure.onClose}>
			<MenuButton as={IconButton} icon={<Icon as={Bars3Icon} />} variant="outline" />
			<MenuList>
				{userState.token ?
					<>
						<MenuItem icon={<Icon as={LinkIcon} />} isDisabled>Share (soon)</MenuItem>
						{funnelsState.aboutToSave && <MenuItem isDisabled={funnelsState.funnelNodes?.length === 0 && funnelsState.funnelEdges?.length === 0} icon={<SaveIcon />} onClick={saveFunnel}>Save</MenuItem>}
						<MenuItem icon={<Icon as={ArrowsRightLeftIcon} />} onClick={toggleView}>Show {!funnelsState.showHits ? 'Hits' : '$'}</MenuItem>
						<MenuItem icon={<Icon as={ArrowTopRightOnSquareIcon} />} onClick={showPortal}>{status === 'active' ? 'Billing' : 'Subscribe'}</MenuItem>
						<MenuItem icon={<LogoutIcon />} onClick={logout}>Logout</MenuItem>
					</> :
					<>
						<MenuItem icon={<Icon as={LinkIcon} />} isDisabled>Share (soon)</MenuItem>
						<MenuItem isDisabled={funnelsState.funnelNodes?.length === 0 && funnelsState.funnelEdges?.length === 0} icon={<SaveIcon />} onClick={saveFunnel}>Save</MenuItem>
						<MenuItem icon={<Icon as={LoginIcon} />} onClick={() => { login(); disclosures.signupModalDisclosure.onClose() }}>Login</MenuItem>
						<MenuItem icon={<Icon as={UserIcon} />} onClick={disclosures.signupModalDisclosure.onOpen}>Signup</MenuItem>
						<MenuItem icon={<Icon as={ArrowsRightLeftIcon} />} onClick={toggleView}>Show {!funnelsState.showHits ? 'Hits' : '$'}</MenuItem>
						<MenuItem icon={<Icon as={CheckCircleIcon} />} onClick={disclosures.promiseModalDisclosure.onOpen}>Promise</MenuItem>
					</>}
			</MenuList>
		</Menu>
	)
}