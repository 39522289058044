import { useContext, useEffect } from 'react'

import { useToast, useDisclosure } from "@chakra-ui/react"

import axios from 'axios'

import { useRouter, } from 'next/router'

import { debounce } from '../../utils/client'
import { FunnelsContext, FunnelsProvider } from '../../store/funnels'
import { UserContext, UserProvider } from '../../store/user'

import GetStartedModal from '../../components/modals/GetStarted'
import SettingsPanel from '../../components/panels/SettingsPanel'
import CanvasContainer from '../../components/canvas/CanvasContainer'
import TopBar from '../../components/topbar/TopBar'
import { DesktopView, SelectProject, TabletView } from '../../components/topbar/TopBarFunnels'

import dynamic from 'next/dynamic'

import SocialHead from '../../components/head/SocialHead'

import 'reactflow/dist/style.css'
import 'reactflow/dist/base.css'

import { useGoogleLogin } from '@react-oauth/google'

const Layout = dynamic(() => import('../../components/shared/Layout'), { ssr: false })

export const Funnels = () => {

    const { state: funnelsState, dispatch: funnelsDispatch, onLoginSuccess } = useContext(FunnelsContext)
    const { state: userState, isUserTokenLoaded } = useContext(UserContext)

    const toast = useToast()
    const router = useRouter()

    const getStartedMenuDisclosure = useDisclosure()

    const saveFunnel = async () => {
        if (router.query.funnelId) {
            const elements = funnelsState.funnelNodes.concat(funnelsState.funnelEdges)
            funnelsDispatch({ type: 'SET_IS_SAVING', value: true })
            await axios.put(`/api/funnels/${router.query.funnelId}`, { elements: elements }, { headers: { authorization: userState.token } })
            funnelsDispatch({ type: 'SET_IS_SAVING', value: false })
        }
    }

    useEffect(() => {
        const status = userState.user?.subscriptionStatus
        if (status === 'active' && funnelsState.aboutToSave) {
            debounce(() => {
                funnelsDispatch({ type: 'SET_ABOUT_TO_SAVE', value: false })
                saveFunnel()
            }, 2000)()
        }
    }, [funnelsState.aboutToSave])

    const getFunnels = async () => {
        // get user funnels
        const { data } = await axios.get(`/api/funnels/list`, { headers: { 'Content-Type': 'application/json', authorization: userState.token } })

        // set funnels
        funnelsDispatch({ type: 'SET_FUNNELS', value: data.funnels })

        if (data.funnels.length > 0) {
            // set funnel
            const index = router.query.funnelId ? data.funnels.findIndex(f => f.id === router.query.funnelId) : data.funnels.length - 1
            const funnelNodes = data.funnels[index].elements.filter(n => n.type === 'element')
            const funnelEdges = data.funnels[index].elements.filter(n => n.type === 'link')
            funnelsDispatch({ type: 'SET_FUNNEL_INDEX', value: index })
            funnelsDispatch({ type: 'SET_FUNNEL_NODES', value: funnelNodes })
            funnelsDispatch({ type: 'SET_FUNNEL_EDGES', value: funnelEdges })

            if (!router.query.funnelId) router.push(`/funnels/${data.funnels[index].id}`, null, { shallow: true })
        
            if (router.query.funnelId) setTimeout(() => funnelsState.instance?.fitView({ maxZoom: 1, duration: 400 }), 10)
        }
    }


    useEffect(() => {
        // redirect
        if (router.isReady && isUserTokenLoaded && router.query.funnelId && !userState.token) router.push(`/`, null, { shallow: true })
    }, [userState.token, isUserTokenLoaded, router.isReady])

    useEffect(() => {
        if (userState.token && funnelsState.instance && router.isReady) {
            getFunnels()
        }
    }, [userState.token, funnelsState.instance, router.isReady])

    useEffect(() => {
        const isFirstTime = localStorage.getItem('first_time') !== 'false'
        if (isFirstTime) getStartedMenuDisclosure.onOpen()
    }, [])

    const login = useGoogleLogin({ flow: 'auth-code', onSuccess: (res) => onLoginSuccess(res, true) })

    return (
        <>
            <SocialHead />
            <Layout>
                <GetStartedModal isOpen={getStartedMenuDisclosure.isOpen} onClose={() => { localStorage.setItem('first_time', false); getStartedMenuDisclosure.onClose() }} />
                <TopBar SelectProject={SelectProject} DesktopView={DesktopView} TabletView={TabletView} elements={funnelsState.funnels} login={login} />
                <CanvasContainer />
                {(funnelsState.selectedElements?.nodes.length > 0 || funnelsState.selectedElements?.edges.length > 0) && <SettingsPanel />}
                {/* {(state.selectedElements.nodes.length > 0 || state.selectedElements.edges.length > 0) && <MidPanel/>} */}
            </Layout>
        </>
    )
}



const Index = () => {
    return (
        <UserProvider>
            <FunnelsProvider>
                <Funnels />
            </FunnelsProvider>
        </UserProvider>
    )
}

export default Index
