import { useState, useRef } from 'react'

import { Flex, Button, Tabs, TabPanels, TabPanel, CircularProgress, Alert, AlertIcon, Icon } from "@chakra-ui/react"

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, useToast } from "@chakra-ui/react"

import { ArrowPathIcon } from '@heroicons/react/20/solid'

import step1 from "../../public/assets/Steps/step1.mp4"
import step2 from "../../public/assets/Steps/step2.mp4"
// import step3 from "../../public/assets/Steps/step3.mp4"

const stepLabels = ['Welcome to Pretty Funnels 👋', 'Adding funnel steps', 'Configuring conversion rates']

const example = require('./../../public/assets/Misc/Example.svg')

const StepVideo = ({step, children}) => {
	const [perc, setPerc] = useState(0)
	const [done, setDone] = useState(false)
	const step1ref = useRef(null)
	const updateProgressBar = (e) => setPerc(e.target.currentTime / e.target.duration * 100)

	return (
		<>
			<div style={{position: 'relative'}}>
				{!done && <CircularProgress value={perc} color="var(--chakra-colors-purple-500)" size="20px" thickness="20px" top="20px" right="20px" position="absolute" opacity="0.6"/>}
				{done && <div style={{position: 'absolute', inset: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<Icon as={ArrowPathIcon} boxSize="12" cursor="pointer" color="#44444436" _hover={{opacity: 0.5, color: 'var(--chakra-colors-purple-500)' }} zIndex="1" onClick={() => {step1ref.current.play(); setDone(false)}} /></div>}
				<video ref={step1ref} autoPlay muted style={{marginBottom: '40px'}} onTimeUpdate={(e) => updateProgressBar(e)} onEnded={() => {setDone(true); setPerc(0)}}>
					<source src={step} loop type="video/mp4"/>
				</video>
			</div>
			<span style={{color: 'var(--chakra-colors-gray-500)', fontSize: '14px'}}>{children}</span>
		</>
	)
}

const GetStartedModal = ({isOpen, onClose}) => {
	const [index, setIndex] = useState(0)
	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior={'inside'}>
				<ModalOverlay />
				<ModalContent marginLeft="12px" marginRight="12px" maxWidth="580px">
					<ModalHeader>{stepLabels[index]} {index > 0 ? `(${index}/${stepLabels.length - 1})` : null}</ModalHeader>
					<ModalCloseButton />
					<ModalBody paddingTop="12px">
						<Tabs index={index}>
							<TabPanels>
								<TabPanel>
									<img src={example} alt='example' draggable="false" style={{ margin: '40px auto' }} />
									{/* <Heading size="md">Welcome to Pretty Funnels</Heading> */}
									<Alert status="info" colorScheme="purple" variant="left-accent" borderInlineStartColor="var(--chakra-colors-purple-500)">
										<AlertIcon />
										<Flex display="block">
										<b>Pretty Funnels</b> is an elegant way to <u><i>visualize</i></u>, <u><i>plan</i></u> and <u><i>optimize</i></u> your digital funnels!
										</Flex>
									</Alert>
								</TabPanel>
								<TabPanel>
									<StepVideo step={step1}>
										<b>Note:</b> The first funnel step always comes out as of type <i>"Acquisition"</i>.
										{/* <b>Note:</b> Funnel step can be connected to multiple other steps. */}
									</StepVideo>
								</TabPanel>
								<TabPanel>
									<StepVideo step={step2}>
										Yay!! That's all. You are good to go! 🎉<br/><br/>
									</StepVideo>
								</TabPanel>
							</TabPanels>
						</Tabs>
					</ModalBody>
					<ModalFooter>
						{index > 0 && <Button mr="auto" variant="ghost" onClick={onClose}>Skip</Button>}
						{index > 0 && <Button mr="10px" onClick={() => setIndex(i => i-1)}>Previous</Button>}
						<Button colorScheme="purple" onClick={() => (index < stepLabels.length -1) ? setIndex(i => i+1) : onClose()}>{index < stepLabels.length -1 ? (index === 0 ? 'Let\'s go!' : 'Next') : 'Gotcha!'}</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
export default GetStartedModal