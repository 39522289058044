import { useEffect, useState, useContext } from 'react'

import { FormControl, InputRightAddon, FormHelperText, Flex, Icon, InputGroup, InputLeftAddon, IconButton } from '@chakra-ui/react'
import { Box, Heading, Checkbox, Input, InputLeftElement } from '@chakra-ui/react'

import { LinkIcon, TrashIcon } from '@heroicons/react/20/solid'

import {calculateHits, removeBrokenLinks} from '../../utils/client/nodes'

import {debounce} from '../../utils/client'
import { FunnelsContext } from '../../store/funnels'

const SettingsPanelElement = () => {
	
	const {state: funnelsState, dispatch: funnelsDispatch} = useContext(FunnelsContext)

	const [leads, setLeads] = useState(funnelsState.selectedElements.nodes[0].data.leads)
	const [cac, setCac] = useState(funnelsState.selectedElements.nodes[0].data.cac)
	const [ltv, setLtv] = useState(funnelsState.selectedElements.nodes[0].data.ltv)
	const [url, setUrl] = useState(funnelsState.selectedElements.nodes[0].data.url)
	const [type, setType] = useState(funnelsState.selectedElements.nodes[0].data.type)
	const [type2, setType2] = useState(funnelsState.selectedElements.nodes[0].data.type2)

	
	const onChange = (val, id, type) => {
		const element = funnelsState.funnelNodes.find(e => e.id === id)
		element.data[type] = val

		const removedBrokenLinks = removeBrokenLinks(funnelsState.funnelNodes, funnelsState.funnelEdges)
		funnelsDispatch({ type: 'SET_FUNNEL_NODES', value: calculateHits(funnelsState.funnelNodes, removedBrokenLinks) })
		funnelsDispatch({ type: 'SET_FUNNEL_EDGES', value: removedBrokenLinks })
		funnelsDispatch({ type: 'SET_ABOUT_TO_SAVE', value: true })
	}

	const onChangeLtv = (e) => { setLtv(e.target.value); onChange(e.target.value, funnelsState.selectedElements.nodes[0].id, 'ltv') }
	const onChangeCac = (e) => { setCac(e.target.value); onChange(e.target.value, funnelsState.selectedElements.nodes[0].id, 'cac') }
	const onChangeLeads = (e) => { setLeads(e.target.value); onChange(e.target.value, funnelsState.selectedElements.nodes[0].id, 'leads') }
	const onChangeUrl = (e) => { setUrl(e.target.value); debounce(() => onChange(e.target.value, funnelsState.selectedElements.nodes[0].id, 'url'), 500)() }
	const onChangeType = (e) => { setType(e.target.checked ? 'acquisition' : 'conversion'); onChange(e.target.checked ? 'acquisition' : 'conversion', funnelsState.selectedElements.nodes[0].id, 'type') }
	// const onChangeType2 = (e) => { setType2((e === 0) ? 'page' : 'url'); onChange((e === 0) ? 'page' : 'url', state.selectedElements.nodes[0].id, 'type2') }

	const deleteSelected = () => {
		const nodes = funnelsState.funnelNodes.filter(e => e.id !== funnelsState.selectedElements.nodes[0].id)
		funnelsDispatch({ type: 'SET_FUNNEL_NODES', value: nodes })
		funnelsDispatch({ type: 'SET_SELECTED_ELEMENTS', value: null })
		funnelsDispatch({ type: 'SET_ABOUT_TO_SAVE', value: true })
	}
	
	useEffect(() => {
		setLeads(funnelsState.selectedElements.nodes[0].data.leads)
		setCac(funnelsState.selectedElements.nodes[0].data.cac)
		setLtv(funnelsState.selectedElements.nodes[0].data.ltv)
		setUrl(funnelsState.selectedElements.nodes[0].data.url)
		setType(funnelsState.selectedElements.nodes[0].data.type)
		setType2(funnelsState.selectedElements.nodes[0].data.type2)
	}, [funnelsState.selectedElements.nodes[0].id])
	return (
		<Flex flexDirection="column">
			<Flex>
				<Heading display="inline-flex" alignItems="center" justifyContent="flex-start" size="md" whiteSpace="nowrap" textOverflow="ellipsis" flex="1" overflow="hidden">{funnelsState.selectedElements.nodes[0].data.name}</Heading>
				<IconButton variant="ghost" icon={<Icon as={TrashIcon} color="gray.500" />} onClick={deleteSelected}/>
			</Flex>
			<FormControl paddingTop="20px">
				<Checkbox colorScheme="purple" isChecked={type === 'acquisition'} onChange={onChangeType}>Acquisition</Checkbox>
			</FormControl>
			{type2 === 'url' &&
				<FormControl marginTop="12px">
					<InputGroup>
						<InputLeftElement pointerEvents="none" children={<Icon as={LinkIcon} color="gray.300" />} />
						<Input placeholder="Url" value={url} onChange={onChangeUrl} />
					</InputGroup>
				</FormControl>}
			{type === 'acquisition' &&
				<FormControl marginTop="12px">
					<InputGroup width="160px">
						<InputLeftAddon children="Leads" />
						<Input type="number" min={0} placeholder="0" value={leads} onChange={onChangeLeads} />
					</InputGroup>
					<InputGroup width="180px" marginTop="12px">
						<InputLeftAddon children="CAC ($)" />
						<Input type="number" min={0} placeholder="0.0" value={cac} onChange={onChangeCac} />
					</InputGroup>
				</FormControl>}
			{type !== 'acquisition' &&
				<FormControl marginTop="20px">
					<InputGroup width="180px">
						<InputLeftAddon children="LTV ($)" />
						<Input type="number" min={0} placeholder="0.0" value={ltv} onChange={onChangeLtv} />
					</InputGroup>
				</FormControl>}
		</Flex>
	)
}

const SettingsPanelLink = () => {
	
	const {state: funnelsState, dispatch: funnelsDispatch} = useContext(FunnelsContext)
	
	const [rate, setRate] = useState(funnelsState.selectedElements.edges[0].data.rate)
	
	const source = funnelsState.funnelNodes.find(e => e.id === funnelsState.selectedElements.edges[0].source)
	const target = funnelsState.funnelNodes.find(e => e.id === funnelsState.selectedElements.edges[0].target)

	const onChangeRate = (e) => {
		setRate(e.target.value)
		const funnelEdges = [...funnelsState.funnelEdges]
		const element = funnelEdges.find(e => e.id === funnelsState.selectedElements.edges[0].id)
		element.data.rate = e.target.value / 100
		element.label = (e.target.value !== '') ? e.target.value + '%' : 'Not set'
		funnelsDispatch({ type: 'SET_FUNNEL_EDGES', value: funnelEdges })
		funnelsDispatch({ type: 'SET_FUNNEL_NODES', value: calculateHits(funnelsState.funnelNodes, funnelsState.funnelEdges) })
		funnelsDispatch({ type: 'SET_ABOUT_TO_SAVE', value: true })
	}

	const deleteSelected = () => {
		const edges = funnelsState.funnelEdges.filter(e => e.id !== funnelsState.selectedElements.edges[0].id)
		funnelsDispatch({ type: 'SET_FUNNEL_EDGES', value: edges })
		funnelsDispatch({ type: 'SET_SELECTED_ELEMENTS', value: null })
		funnelsDispatch({ type: 'SET_ABOUT_TO_SAVE', value: true })
	}
	
	useEffect(() => setRate(funnelsState.selectedElements.edges[0].data.rate * 100), [funnelsState.selectedElements.edges[0].id])

	return (
		<>
			<Flex>
				<Heading display="inline-flex" alignItems="center" justifyContent="flex-start" size="md" whiteSpace="nowrap" textOverflow="ellipsis" flex="1" overflow="hidden">{source?.data.name}<span style={{ fontSize: '16px' }}>&nbsp;&nbsp;→&nbsp;&nbsp;</span>{target?.data.name}</Heading>
				<IconButton variant="ghost" icon={<Icon as={TrashIcon} color="gray.500" />} onClick={deleteSelected}/>
			</Flex>
			<FormControl>
				<InputGroup width="120px" marginTop="20px">
					<Input type="number" min={0} max={100} placeholder="0" value={rate !== 0 ? rate : ''} onChange={onChangeRate} />
					<InputRightAddon children="%" />
				</InputGroup>
				<FormHelperText>What % moves to the next step?</FormHelperText>
			</FormControl>
		</>
	)
}

const SettingsPanel = () => {
	const {state: funnelsState } = useContext(FunnelsContext)

	return (
		<Box position="absolute" top="20px" left="20px" zIndex="30">
			<Box width="320px" borderWidth="1px" borderRadius="lg" backgroundColor="white">
				<Box p="6">
					<Box d="flex" mt="2" alignItems="center">
						<Box ml="2" color="gray.600" fontSize="sm" width="100%">
							{(funnelsState.selectedElements?.nodes.length > 0 ) ? <SettingsPanelElement/> : <SettingsPanelLink/> }
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
export default SettingsPanel