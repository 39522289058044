import { useContext } from 'react'

import { Box, Heading, Badge } from '@chakra-ui/react'

import {calculateTotalCac, calculateTotalLtv} from '../../utils/client/nodes'
import { FunnelsContext } from '../../store/funnels'
import {nFormatter} from '../../utils/client'

const ProfitPanel = () => {
    const {state: funnelsState} = useContext(FunnelsContext)

	const totalCac = calculateTotalCac(funnelsState.funnelNodes)
	const totalLtv = calculateTotalLtv(funnelsState.funnelNodes)
	const totalProfit = totalLtv - totalCac
    return (
        !funnelsState.showHits && 
            <Box position="absolute" top="76px" right="20px" borderWidth="1px" borderRadius="lg" padding="8px 14px">
                <Heading size="sm">
                    {'Net: '}
                    <Badge fontSize="18px" marginBottom="2px" colorScheme={totalProfit >= 0 ? (totalProfit === 0 ? "blackAlpha" : "green") : "red"}>
                        ${nFormatter(Math.abs(Math.round(totalProfit)))}
                    </Badge>
                </Heading>
            </Box>
    )
}
export default ProfitPanel